<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <h1 class="text-royalblue-900 mb-10">Page not found</h1>
      <p class="h5 text-royalblue-400 mb-8 leading-relaxed">
        Oops, it looks like the page you're looking for doesn't exist.
      </p>
      <base-btn url="Home" class="mt-6 md:mt-8" :icon-before="true">
        Back to homepage
      </base-btn>
    </div>
  </section>
</template>

<script>
import BaseBtn from "../components/ui/BaseBtn.vue";

export default {
  name: "NotFound",
  components: {
    "base-btn": BaseBtn,
  },
};
</script>
